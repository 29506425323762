import React from "react";

function Button(props) {
  return (
    <button
      className="bg-foreground py-4 px-10 
      rounded-3xl text-white font-xl 
      hover:bg-secondary hover:text-primary
      font-bold
      dark:bg-secondary dark:text-black
      dark:hover:bg-primary dark:hover:text-white
      "
      {...props}
    >
      {props.children}
    </button>
  );
}

export default Button;
