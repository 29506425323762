import { db } from "../firebase-setup/firebase";
import { get, ref } from "firebase/database";
import Crypto from "./Crypto";

// function to fetch from database of the given id
// added encryption and decryption
async function fetchData(id) {
  const snapshot = await get(ref(db, `/${id}`));
  console.log(snapshot.val().encryptedText);
  if (snapshot.exists()) {
    const finalText = JSON.parse(snapshot.val().finalText);
    const encryptedData = finalText.encryptedData;
    var decryptedContent = Crypto(encryptedData, "Password", "Dog", false);
    console.log("Content Pulled form DB: " + decryptedContent);
    return JSON.parse(decryptedContent);
  } else {
    console.log("No data available");
    return null;
  }
}

export default fetchData;
