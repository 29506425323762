/** Reproducing this component
 * npm install --save @ckeditor/ckeditor5-react
 * https://www.datainfinities.com/3/how-to-integrate-custom-build-ckeditor5-with-react use this to link to download custom editor
 * create ckeditor folder at the root folder of the project, and put the custom editor files inside it
 * npm add file:./ckeditor to add custom build to the node modules
 * npm install semantic-ui-css semantic-ui-react
 **/
import React, { useState, useContext, useRef, useEffect } from "react";
import LanguageContext from "../../context/language-context";
import ThemeContext from "../../context/theme-context";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Button } from "semantic-ui-react";
import "../../assets/styles/ckeditor.css";
import { FaTrashAlt } from "react-icons/fa";
import Modal from "react-modal";
/**
 * imports the language dynamically to load into ckeditor
 * @param {*} language the language to import
 * @returns languagemodule if it exists in ckeditor, else returns default english language
 */
function importLanguageModule(language) {
  try {
    const languageModule = require(`ckeditor5-custom-build/build/translations/${language}.js`);
    return languageModule.default;
  } catch (error) {
    console.warn("Language could not be imported, Defaulting to English...");
    return "en-gb";
  }
}

function CkEditorCore({ handleChildContent, localStorageName }) {
  // these parameters handle the theme of the editor
  const { theme, setTheme } = useContext(ThemeContext);
  const editorClassTheme = theme === "dark" ? "dark-mode" : "light-mode";
  // these parameter handle the language of the editor
  const { language, setLanguage } = useContext(LanguageContext);
  const [editorLanguage, setEditorLanguage] = useState("en-gb");
  // this parameter handles the toolbar reference for the document editor
  const toolBarRef = useRef(null);
  // these parameter handle the typing state, and set the counts, and button respectively
  const timeoutRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [wordCount, setWordCount] = useState({ characters: 0, words: 0 });
  //for wrapping editor to avoid resize observer limit
  const ref = useRef();
  const [height, setHeight] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [deleteError, setDeleteError] = useState("");
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setHeight(entry.contentRect.height);
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  // this parameter is used to handle the editor content
  const [content, setContent] = useState(() => {
    let localStorageItem = JSON.parse(localStorage.getItem(localStorageName));
    if (localStorageItem) {
      return localStorageItem;
    } else {
      return "";
    }
  });
  // useeffect to handle the language change of the editor
  useEffect(() => {
    if (language === "en") {
      setEditorLanguage("en-gb");
    } else {
      setEditorLanguage(language);
    }
  }, [language]);
  // useeffect to handle the typing status inside the editor
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  // function to handle the change in the editor content
  function handleContentChange(event, editor) {
    const data = editor.getData();
    setContent(data);
    handleChildContent(data);
    // the code to handle the blinker
    setIsTyping(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 2000);
    // the code to get the word and character count
    const wordCountPlugin = editor.plugins.get("WordCount");
    const words = wordCountPlugin.words;
    const characters = wordCountPlugin.characters;
    setWordCount({ words, characters });
  }
  /** editor configuration */
  const editorConfiguration = {
    language: editorLanguage,
    translations: { [language]: importLanguageModule(editorLanguage) },
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "subscript",
      "superscript",
      "|",
      "alignment",
      "pagebreak",
      "|",
      "fontfamily",
      "fontsize",
      "|",
      "fontColor",
      "fontBackgroundColor",
      "highlight",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "outdent",
      "indent",
      "|",
      "link",
      "|",
      "code",
      "codeBlock",
      "|",
      "insertTable",
      "specialcharacters",
      "|",
      "blockQuote",
      "|",
      "findandreplace",
      "|",
      "undo",
      "redo",
    ],
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableCellProperties",
        "tableProperties",
      ],
    },
    // displaying the word and character count
    wordCount: {
      onUpdate: (stats) => {
        setWordCount(stats);
      },
    },
  };

  // clear the text of the CKeditor
  // delete the item in local storage
  const handleClear = (event) => {
    event.preventDefault();
    // openModal();
    // const confirmed1 = window.confirm("Are you sure you want to clear?");
    // if (confirmed1) {
    //   const confirmed2 = window.confirm(
    //     "Again Confirm? Press 'Cancel' to cancel, Press 'OK' to clear."
    //   ); // swap "OK" and "Cancel"
    if (deleteText === "delete") {
      localStorage.removeItem(localStorageName);
      // pass a specific string to the parent component which will remove the question
      handleChildContent("!d(l@t)!");
      setContent("");
      setDeleteText("");
      setDeleteError("");
      closeModal();
    } else {
      setDeleteError("Please type delete");
    }
    // }
  };

  return (
    <div
      ref={ref}
      // style={{ height: `${height}px` }}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="fixed inset-0 bg-white bg-opacity-80 bg-opacity-0 z-10"
        className="w-[40vw] h-[27vw] top-0 bottom-0 left-0 right-0 m-auto bg-secondary absolute"
      >
        <h1 className="text-black text-center">
          Are you sure you want to delete
        </h1>
        <p className="text-center">All of the progress will be deleted</p>
        <form className="flex flex-col justify-center items-center gap-2">
          <p>
            Please type <i>delete</i> below to delete the contents.
          </p>
          <input
            className="bg-white "
            value={deleteText}
            onChange={(e) => {
              setDeleteText(e.target.value);
            }}
          />
          <button
            className="px-4 py-2 text-white bg-primary rounded-3xl"
            onClick={handleClear}
          >
            Delete
          </button>
          <span className="text-white">{deleteError}</span>
        </form>
      </Modal>
      <div className={editorClassTheme}>
        <div className="">
          <div className="flex justify-between p-4">
            <div className="word-char-button">
              {/* {isTyping ? (
              <div className="blinking-indicator green-blink" />
            ) : (
              <div className="blinking-indicator orange" />
            )} */}
              <div className="word-char-count">
                Characters: {wordCount.characters} Words: {wordCount.words}
              </div>
              <div className="loading-button">
                {isTyping ? (
                  <Button basic loading>
                    Loading
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: "0.3rem" }}
                    content="Draft Saved"
                    basic
                  />
                )}
              </div>
            </div>
            <div className="delete-button">
              <button className="dark:text-white" onClick={openModal}>
                <FaTrashAlt />
              </button>
            </div>
          </div>

          <div className="document-editor">
            <div ref={toolBarRef} className="document-editor__toolbar"></div>
            <div className="document-editor__editable-container">
              <div className="document-editor__editable"></div>
              <CKEditor
                key={editorLanguage}
                editor={Editor}
                data={content}
                config={editorConfiguration}
                onChange={handleContentChange}
                onReady={(editor) => {
                  if (toolBarRef.current) {
                    toolBarRef.current.innerHTML = ""; // Remove old toolbar
                    toolBarRef.current.appendChild(
                      editor.ui.view.toolbar.element
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CkEditorCore;
