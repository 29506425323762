import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button";

export default function ViewReport({ target }) {
  //   const langResources = resources[language];
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  let timerId = null;

  useEffect(() => {
    if (countdown > 0) {
      timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }
    return () => clearInterval(timerId);
  }, [countdown, setIsButtonDisabled]);

  function handleClickViewExplanation() {
    console.log("hello");
    console.log(target);
    navigate(target);
  }

  const buttonText =
    countdown <= 0 ? " View report" : `Wait for ${countdown} seconds`;

  return (
    <>
      <Button onClick={handleClickViewExplanation} disabled={isButtonDisabled}>
        {buttonText}
      </Button>
    </>
  );
}
