import React, { useContext } from "react";
import language_resorces from "../data/translation-language";
import SliderComponent from "../components/contributor-section/contributor-slider";
import PromptButton from "../components/button-prompt-navigate/prompt-button";
import MainLayout from "../layouts/main-layout";
import ThemeContext from "../context/theme-context";
import LanguageContext from "../context/language-context";
import ButtonDropDown, { DropDownItem } from "../components/buttonDropdown";

export default function RenderLandingPage() {
  const { theme, setTheme } = useContext(ThemeContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const langResources = language_resorces[language];

  function JumboTron(props) {
    return (
      <div className="p-8 bg-backgroundSecondary min-h-[320px] rounded-3xl shadow-2xl my-8">
        {props.children}
      </div>
    );
  }

  function RenderOptions() {
    /**
     * Function that displays options to various features of the website.
     */
    return (
      <div className="form-button">
        {/* On accepting -> Directs to Writing Page */}
        <PromptButton
          buttonText={langResources.landing_page_startOptionOne}
          promptText={langResources.landing_page_termsAndConditions}
          target={"/writing"}
        />
        {/* On accepting -> Directs to OneCompiler Page */}
        <PromptButton
          buttonText={langResources.landing_page_startOptionTwo}
          promptText={langResources.landing_page_termsAndConditions}
          target={"/onecompiler"}
        />
        {/* On accepting -> Directs to Judge0 Page */}
        <PromptButton
          buttonText={langResources.landing_page_startOptionThree}
          promptText={langResources.landing_page_termsAndConditions}
          target={"/judge0"}
        />
        {/* On accepting -> Directs to GoogleColab Page */}
        <PromptButton
          buttonText={langResources.landing_page_startOptionFour}
          promptText={langResources.landing_page_termsAndConditions}
          target={"/notebook"}
        />
        <hr />
        <h3>Researcher's corner</h3>
        <h4>View someone else's report</h4>
        <div>
          {/* Directs to report route to handle Writing ZIP. */}
          <PromptButton
            buttonText={langResources.landing_page_uploadWritingExistingAnswer}
            promptText={""}
            target={"/writingreport"}
          />
          {/* Directs to report route to handle Code ZIP. */}
          <PromptButton
            buttonText={langResources.landing_page_uploadCodeExistingAnswer}
            promptText={""}
            target={"/codereport"}
          />

          <PromptButton
            buttonText={langResources.landing_page_buttonQuestionUpload}
            promptText={""}
            target={"/uploadquestion"}
          />
        </div>
      </div>
    );
  }

  return (
    <MainLayout>
      <JumboTron>
        <h1 className="text-primary dark:text-white text-4xl font-medium">
          Participants
        </h1>
        <p className="mt-4 dark:text-white">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
        </p>
        <div className="flex justify-center gap-16 mt-8 flex-wrap">
          <ButtonDropDown
            name={langResources.landing_page_startOptionOne}
            promptText={langResources.landing_page_termsAndConditions}
            target="/writing"
          >
            <DropDownItem
              buttonText={langResources.landing_page_startOptionTwo}
              promptText={langResources.landing_page_termsAndConditions}
              target={"/onecompiler"}
            />
          </ButtonDropDown>
          <ButtonDropDown
            name={langResources.landing_page_startOptionThree}
            promptText={langResources.landing_page_termsAndConditions}
            target={"/judge0"}
          >
            <DropDownItem
              buttonText={langResources.landing_page_startOptionFour}
              promptText={langResources.landing_page_termsAndConditions}
              target={"/notebook"}
            />
          </ButtonDropDown>
        </div>
      </JumboTron>
      <JumboTron>
        <h1 className="text-primary dark:text-white text-4xl font-medium">
          Participants
        </h1>
        <p className="mt-4 dark:text-white">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.
        </p>
        <div className="flex justify-center gap-16 mt-8 flex-wrap">
          <PromptButton
            buttonText={langResources.landing_page_uploadWritingExistingAnswer}
            promptText={""}
            target={"/writingreport"}
          />
          <PromptButton
            buttonText={langResources.landing_page_uploadCodeExistingAnswer}
            promptText={""}
            target={"/codereport"}
          />
          <PromptButton
            buttonText={langResources.landing_page_buttonQuestionUpload}
            promptText={""}
            target={"/uploadquestion"}
          />
        </div>
      </JumboTron>
      <div className="flex md:justify-between justify-center items-center flex-wrap md:flex-nowrap">
        <div className="md:w-[35%] md:max-w-[500px] w-[380px]">
          <img
            src="https://placehold.co/600x400"
            className="w-full"
            alt="representing about us section"
          ></img>
        </div>
        <div className="md:w-[60%]">
          <h1 className="text-primary font-bold dark:text-white text-3xl">
            About the Project
          </h1>
          <p className="mt-4 dark:text-white">
            Machine and deep learning have great potential for improving
            products, processes, and research. But computers usually do not
            explain their predictions. This website is about making machine and
            deep learning models and their decisions interpretable. After
            exploring the concepts of interpretability, participants will learn
            about simple, interpretable models such as decision trees, decision
            rules, and linear regression. The app will introduce model-agnostic
            methods for interpreting black box models, such as feature
            importance and accumulated local effects, and explain individual
            predictions with Shapley values and LIME. After discussing the
            limitations of these methods, the online course will survey modern
            interpretability methods, mainly focusing on understanding black-box
            deep neural network methods. Emerging and more promising
            interpretability methods are concept-based, which involve detecting
            human concepts from network activations. In computer vision, for
            example, when predicting if a wood-block tower will fall, we may ask
            the network `”What human-understandable concept in the input tower
            block is causing the output?”.
          </p>
        </div>
      </div>
      <div className="first-page-conatiner hidden">
        <div className="text-conatiner">
          <h2 className="intro-text-max">
            {langResources.landing_page_welcomeMessage}
          </h2>
          <h4 className="intor-text-mini">
            {langResources.landing_page_meaningfulExplanations}
          </h4>
        </div>
        <div className="starting-form">
          <RenderOptions />
        </div>
        <hr />
        <h3>About the project</h3>
        Machine and deep learning have great potential for improving products,
        processes, and research. But computers usually do not explain their
        predictions. This website is about making machine and deep learning
        models and their decisions interpretable. After exploring the concepts
        of interpretability, participants will learn about simple, interpretable
        models such as decision trees, decision rules, and linear regression.
        The app will introduce model-agnostic methods for interpreting black box
        models, such as feature importance and accumulated local effects, and
        explain individual predictions with Shapley values and LIME. After
        discussing the limitations of these methods, the online course will
        survey modern interpretability methods, mainly focusing on understanding
        black-box deep neural network methods. Emerging and more promising
        interpretability methods are concept-based, which involve detecting
        human concepts from network activations. In computer vision, for
        example, when predicting if a wood-block tower will fall, we may ask the
        network ``What human-understandable concept in the input tower block is
        causing the output?".
        <hr />
        <h3>Contributors</h3>
        <SliderComponent />
      </div>
    </MainLayout>
  );
}
