// sets the global context of the theme
// set the context to light or dark
import React from "react";

// set the defaults
const ThemeContext = React.createContext({
  theme: "light",
  setTheme: () => {},
});

export default ThemeContext;
