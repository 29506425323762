import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button";
// Handles Terms and Conditions for proceeding from Landing Page to required target page.
function PromptButton({ buttonText, promptText, target }) {
  const navigate = useNavigate();

  function handleButtonClick() {
    if (promptText) {
      const acceptTerms = window.confirm(promptText);
      if (acceptTerms) {
        navigate(target);
      }
    } else {
      navigate(target);
    }
  }
  return (
    <>
      <Button onClick={handleButtonClick}>{buttonText}</Button>
    </>
  );
}

export default PromptButton;
