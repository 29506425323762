import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaRegMoon, FaRegSun } from "react-icons/fa";

// importing contexts
import ThemeContext from "../../context/theme-context";
import LanguageContext from "../../context/language-context";

import { languages } from "../../data/language-options";
import language_resorces from "../../data/translation-language";

import "../../assets/styles/navbar.css";

import logo_light from "../../assets/images/logo-light.png";
import logo_dark from "../../assets/images/logo-dark.png";

function NavigationLink(props) {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        isActive ? "font-bold underline" : "font-medium"
      }
    >
      {props.children}
    </NavLink>
  );
}
function DropDown(props) {
  const [toggle, setToggle] = useState(0);

  return (
    <div className="relative">
      <a
        href="##"
        className="font-medium"
        onClick={() => {
          setToggle((prev) => !prev);
        }}
      >
        {props.name} <i className="fas fa-caret-down"></i>
      </a>
      <div
        className={
          `${toggle ? "flex " : "hidden "}` +
          "md:absolute relative md:top-10 bg-secondary dark:bg-secondaryDark flex-col gap-2 p-2"
        }
      >
        {props.children}
      </div>
    </div>
  );
}
function NavbarComponent() {
  const { language, setLanguage } = useContext(LanguageContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const langResources = language_resorces[language];
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  // handle toggle theme
  function handleToggleTheme() {
    if (theme === "light") {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    } else {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    }
  }

  // handle language change
  function handleLanguageChange(event) {
    const selectedLanguage = languages.find(
      (lang) => lang.code === event.target.value
    );
    console.log(selectedLanguage);
    setLanguage(selectedLanguage.code);
  }
  // let langResources = navbar_language_resources[language];

  return (
    <nav className="bg-secondary dark:bg-secondaryDark">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center w-full justify-between h-16 gap-2">
          <div className="flex w-full dark:text-white justify-start">
            <a href="/" className="flex items-center gap-2 w-[20rem]">
              {theme === "light" ? (
                <img src={logo_light} alt="Company Logo" className="logo" />
              ) : (
                <img src={logo_dark} alt="Company Logo" className="logo" />
              )}
              Process Feedback
            </a>
            <div className="w-full hidden nav:flex justify-start gap-[4%] items-center">
              <NavigationLink to="/">
                {langResources.navbar_home}
              </NavigationLink>
              <DropDown name="About Us">
                <NavigationLink to="/articles">
                  {langResources.navbar_menuItemRelatedArticles}
                </NavigationLink>
                <NavigationLink to="/story">
                  {langResources.navbar_menuItemStory}
                </NavigationLink>
              </DropDown>
              <NavigationLink to="/articles">
                {langResources.navbar_menuItemRelatedArticles}
              </NavigationLink>
              <NavigationLink to="/story">
                {langResources.navbar_menuItemStory}
              </NavigationLink>
              <NavigationLink to="/manuscript">
                {langResources.navbar_menuItemManuscript}
              </NavigationLink>
              <NavigationLink to="/samplereports">
                {langResources.navbar_menuItemReports}
              </NavigationLink>
              <NavigationLink to="/media">
                {langResources.navbar_menuItemMedia}
              </NavigationLink>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex items-center gap-2 ">
              <div className={`${theme}`}>
                <select
                  className="decorated bg-white dark:bg-foregroundDark 
                  py-2 px-6 rounded-3xl"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  {languages.map((lang) => (
                    <option
                      key={lang.code}
                      value={lang.code}
                      className="bg-white dark:bg-foregroundDark"
                    >
                      {lang.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="checkbox opacity-0 absolute"
                  id="checkbox"
                  onChange={handleToggleTheme}
                  checked={theme === "dark"}
                />
                <label
                  for="checkbox"
                  className="label w-[50px] height-[26px] bg-primary dark:bg-secondary flex 
                  rounded-[50px] items-center justify-between p-[5px] relative "
                >
                  <i className="fas fa-sun text-accent dark:text-primary"></i>
                  <i className="fas fa-moon  text-accent dark:text-primary"></i>
                  <div
                    className="ball w-[20px] h-[20px] bg-accent dark:bg-primary absolute 
                  top-[2px] left-[2px] rounded-[50%] cursor-pointer"
                  />
                </label>
              </div>
              {/* <button className="">
                {theme === "light" ? <FaRegMoon /> : <FaRegSun />}
              </button> */}
            </div>
            <div className="-mr-2 flex nav:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 
              rounded-md text-white hover:text-white 
              bg-primary
              "
                aria-label="Main menu"
                aria-expanded="false"
              >
                <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                  <path
                    fillRule="evenodd"
                    d="M3 6h18a1 1 0 110 2H3a1 1 0 110-2zm0 5h18a1 1 0 110 2H3a1 1 0 110-2zm0 5h18a1 1 0 110 2H3a1 1 0 110-2z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${isOpen ? "block" : "hidden"} nav:hidden dark:text-white`}
      >
        <div className="px-2 pt-2 pb-3 flex flex-col gap-2">
          <NavigationLink to="/">{langResources.navbar_home}</NavigationLink>
          <NavigationLink to="/articles">
            {langResources.navbar_menuItemRelatedArticles}
          </NavigationLink>
          <DropDown name="About Us">
            <NavigationLink to="/articles">
              {langResources.navbar_menuItemRelatedArticles}
            </NavigationLink>
            <NavigationLink to="/story">
              {langResources.navbar_menuItemStory}
            </NavigationLink>
          </DropDown>
          <NavigationLink to="/story">
            {langResources.navbar_menuItemStory}
          </NavigationLink>
          <NavigationLink to="/manuscript">
            {langResources.navbar_menuItemManuscript}
          </NavigationLink>
          <NavigationLink to="/samplereports">
            {langResources.navbar_menuItemReports}
          </NavigationLink>
          <NavigationLink to="/media">
            {langResources.navbar_menuItemMedia}
          </NavigationLink>
        </div>
      </div>
    </nav>
  );
}

export default NavbarComponent;
