import React from "react";
import NavbarComponent from "./navbar/navbar";
import FooterComponent from "./footer/footer";
function MainLayout({ children }) {
  return (
    <div className="bg-accent dark:bg-primaryDark">
      <NavbarComponent />
      <div className="max-w-[1600px] p-4 m-auto min-h-[77vh] dark:text-white">
        {children}
      </div>
      <FooterComponent />
    </div>
  );
}

export default MainLayout;
