export default function Crypto(data, secretKey, randomWord, encrypt = true) {
  /**
   * Function to encrypt and decrypt the JSON file.
   *
   * @param {string}  secretKey Used to generate Key using MD5
   * @param {string}  randomWord  Used to create Initialization Vector
   * @return {string} encrypted or decrypted text.
   */
  console.log("Inside Crypto component");
  // Import crypto-js module
  var CryptoJS = require("crypto-js");

  // Generate a key from a string using MD5
  var key = CryptoJS.MD5(
    localStorage.getItem("encryption_passcode") +
      localStorage.getItem("encryption_passcode") +
      localStorage.getItem("encryption_passcode")
  );

  // Generate an IV from another string
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_IV_KEY);

  // Encrypt some data using AES with CBC mode and PKCS7 padding
  if (encrypt === true) {
    console.log("Encrypting...");
    var ciphertext = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // Print the results
    console.log("Encrypted Text(Returning): " + ciphertext.toString());
    console.log("Leaving Crypto component");
    return ciphertext.toString();
  } else if (encrypt === false) {
    console.log("Decrypting...");
    // Decrypt the ciphertext using the same key and IV
    var plaintext = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert the plaintext to a string
    plaintext = plaintext.toString(CryptoJS.enc.Utf8);
    // Print the results
    console.log(plaintext);
    console.log("Leaving Crypto component");
    return plaintext;
  }
}
