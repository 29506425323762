import React from "react";
import PersonCard from "./person-card";
import contributor_information from "../../data/contributor-data";

// function to render the contributor section on the landing page
const SliderComponent = () => {
  return (
    <div className="people-list">
      {contributor_information.map((person) => (
        <PersonCard
          key={person.id}
          name={person.name}
          position={person.position}
          image={person.image}
        />
      ))}
    </div>
  );
};

export default SliderComponent;
