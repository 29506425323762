import React from "react";

const PersonCard = ({ image, name, position }) => {
  return (
    <div className="person-card">
      <img src={image} alt={name} />
      <h2>{name}</h2>
      <p>{position}</p>
    </div>
  );
};

export default PersonCard;
