// LanguageContext.js
// sets the global context of the language
// set the context to language code
import React from "react";

// set the defaults
const LanguageContext = React.createContext({
  language: "en",
  setLanguage: () => {},
});

export default LanguageContext;
