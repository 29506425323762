// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD7RbFGdV3f3MmbEKUNwjRcZfI3MHQybtA",
  authDomain: "filehandling-a25a0.firebaseapp.com",
  projectId: "filehandling-a25a0",
  storageBucket: "filehandling-a25a0.appspot.com",
  messagingSenderId: "288328145895",
  appId: "1:288328145895:web:744000295097508beabb42",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
