import React, { useContext } from "react";
// using react-icons for the socail-media icons
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import "../../assets/styles/footer.css";

function FooterComponent({ theme }) {
  return (
    <div className="bg-secondary dark:bg-secondaryDark dark:text-white p-8">
      <div className={`max-w-[1600px] mx-auto`}>
        <div className="flex justify-center">
          <FaFacebook className="w-12" size={22} />
          <FaInstagram className="w-12" size={22} />
          <FaTwitter className="w-12" size={22} />
          <FaWhatsapp className="w-12" size={22} />
        </div>

        <div className={"flex gap-2 justify-center mt-5"}>
          <div className="">
            <h4 align="center">Contact: processfeedbackteam@gmail.com </h4>
            <h4 align="center">
              © Badri Adhikari, UMSL (should align to the right on the same
              line)
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
