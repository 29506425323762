const contributor_information = [
  {
    id: 101,
    name: "John Doe",
    position: "Software Engineer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 102,
    name: "Jane Doe",
    position: "Product Manager",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 103,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 104,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 105,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 106,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 107,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 108,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 109,
    name: "Bob Smith",
    position: "Designer",
    image: "https://via.placeholder.com/150",
  },
];

export default contributor_information;
